<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Bin Services
      <b-button type="is-primary" @click="newService" slot="right">Create</b-button>
    </hero-bar>
    <section class="section is-main-section">
      <card-component
        class="has-table has-mobile-sort-spaced"
      >

        <b-table
            v-if="binServices.length || isTableLoading"
            :data="binServices"
            :loading="isTableLoading"
            hoverable
        >

            <b-table-column field="label" label="Label" sortable v-slot="props">
                {{ props.row.label }}
            </b-table-column>

            <b-table-column field="code" label="Code" sortable v-slot="props">
                {{ props.row.code }}
            </b-table-column>

            <b-table-column v-slot="props" label="Action" centered>
              <b-field grouped position="is-centered">
                <div class="control">
                  <b-button type="is-primary" @click="updateService(props.row)" size="is-small">Edit</b-button>
                </div>
                <div class="control">
                  <b-button type="is-danger" @click="deleteService(props.row.code)" size="is-small">Delete</b-button>
                </div>
              </b-field>
            </b-table-column>

        </b-table>

        <div class="content has-text-grey has-text-centered empty" v-else>
          <p>
            <b-icon icon="playlist-remove" size="is-large" />
          </p>
          <p>No records found. Click create to start adding records.</p>
        </div>
      </card-component>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import AddEditLookupModal from '@/modals/AddEditLookup'

import lookups from '@/services/lookups'

export default {
  name: 'RoutesList',
  components: {
    TitleBar,
    HeroBar,
    CardComponent
  },
  data () {
    return {
      binServices: [],
      isTableLoading: false
    }
  },
  async mounted () {
    try {
      this.isTableLoading = true
      this.binServices = await lookups.getBinServices()
      this.isTableLoading = false
    } catch (error) {
      this.$buefy.snackbar.open({
        message: error.message,
        queue: false
      })
    }
  },
  methods: {
    ...mapActions('lookups', ['getBinServices']),
    deleteService (code) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete service?',
        onConfirm: async () => {
          try {
            this.binServices = await lookups.deleteBinService(code)
            this.$buefy.snackbar.open({
              message: 'Service deleted successfully',
              queue: false
            })
            this.getBinServices()
          } catch (error) {
            this.$buefy.snackbar.open({
              message: error.message,
              queue: false
            })
          }
        }
      })
    },
    newService () {
      this.$buefy.modal.open({
        component: AddEditLookupModal,
        parent: this,
        props: {
          title: 'New Service'
        },
        events: {
          onSave: async ({ data, close }) => {
            try {
              this.binServices = await lookups.addBinService(data)
              this.$buefy.snackbar.open({
                message: 'Service added successfully',
                queue: false
              })
              this.getBinServices()
              close()
            } catch (error) {
              this.$buefy.snackbar.open({
                message: error.message,
                queue: false
              })
            }
          }
        }
      })
    },
    updateService (row) {
      const { label, code } = row
      this.$buefy.modal.open({
        component: AddEditLookupModal,
        parent: this,
        props: {
          isEdit: true,
          title: 'Update Service',
          label,
          code
        },
        events: {
          onSave: async ({ data, close }) => {
            try {
              this.binServices = await lookups.updateBinService(code, data)
              this.$buefy.snackbar.open({
                message: 'Service updated successfully',
                queue: false
              })
              this.getBinServices()
              close()
            } catch (error) {
              this.$buefy.snackbar.open({
                message: error.message,
                queue: false
              })
            }
          }
        }
      })
    }
  },
  computed: {
    titleStack () {
      return ['Settings']
    }
  }
}
</script>
